
/**
 * @name: 仓储管理-档口配货记录
 * @author:
 * @date: 2023-08-04 17:53
 * @description： 仓储管理-档口配货记录
 * @update: 2023-08-04 17:53
 */
import {Vue, Component} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import {exportDistributeRecordApi, getDistributeRecordApi} from "@/apis/storage/gateDist";
import {IDistributeRecord} from "@/apis/storage/gateDist/types";
import {gateGetGateListApi} from "@/apis/gate/info";
import {deepCopy, exportFile} from "@/utils/common";

@Component({})
export default class storageGateDist extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IDistributeRecord = {
    page: 1,
    limit: 10
  }
  // 配货数量
  distributeStock: string = '0.00'
  // 配货金额
  distributionAmount: string = '0.00'
  // 表单参数
  modelForm: any = {}
  gateList: any = []
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "配货记录ID",
        prop: "id",
        align: 'center',
      },
      {
        label: "档口名称",
        prop: "gateName",
        align: 'center',
      },
      {
        label: "档口名称",
        prop: "gateIdList",
        align: "center",
        search: true,
        searchMultiple: true,
        type: 'select',
        dicUrl: "/api/admin/gate/getGateList",
        dicFormatter: (res: any) => ({list: res.data, label: 'gateName', value: 'id'}),
        hide: true
      },
      {
        label: "商品名称",
        prop: "productName",
        align: 'center',
        search: true,
      },
      {
        label: "配货数量",
        prop: "checkQtyStr",
        align: 'center',
      },
      {
        label: "配货价",
        prop: "purchasePriceStr",
        align: 'center',
      },
      {
        label: "配货金额(元)",
        prop: "productTotalPrice",
        align: 'center',
      },
      {
        label: "批次号",
        prop: "batchNo",
        align: 'center',
      },
      {
        label: "经办人",
        prop: "realName",
        align: 'center',
      },
      {
        label: "配货时间",
        prop: "distributeTime",
        align: "center",
        type: "daterange",
        width: 180,
        //@ts-ignore
        default: '--',
        // @ts-ignore
        defaultTime: ["06:00:00", "05:59:59"],
        search: true,

      },
    ]
  }

  getList() {
    const form: IDistributeRecord = deepCopy(this.queryParam);
    if (this.queryParam.distributeTime && this.queryParam.distributeTime.length) {
      form.startTime = this.queryParam.distributeTime[0]
      form.endTime = this.queryParam.distributeTime[1]
    } else {
      form.startTime = ""
      form.endTime = ""
    }
    delete form.distributeTime
    form.gateIdList = form.gateIdList?.toString()
    getDistributeRecordApi(form).then(e => {
      //@ts-ignore
      this.tableData = e.distributeRecordVoCommonPage.list;
      this.tableTotal = e.distributeRecordVoCommonPage.total
      this.distributeStock = e.distributeStock
      this.distributionAmount = e.distributionAmount
    })
  }

  /**
   * 导出excel
   */
  exportExcel() {
    const form: IDistributeRecord = deepCopy(this.queryParam);
    if (this.queryParam.distributeTime && this.queryParam.distributeTime.length) {
      form.startTime = this.queryParam.distributeTime[0]
      form.endTime = this.queryParam.distributeTime[1]
    } else {
      form.startTime = ""
      form.endTime = ""
    }
    delete form.distributeTime
    delete form.limit
    delete form.page
    form.gateIdList = form.gateIdList || []
    exportDistributeRecordApi(form).then(e => {
      exportFile(e, '档口配货记录.xlsx')
    })
  }

  /**
   * 获取档口下拉列表
   */
  getGateList() {
    gateGetGateListApi().then(e => {
      this.gateList = e.map((item: any) => {
        return {
          label: item.gateName,
          value: item.id
        }
      });
      // @ts-ignore
      this.$refs.crudRef.updateSearchColumn("gateIdList", {
        dicData: this.gateList
      })
    })
  }

  created() {
    // this.getGateList()
    this.getList()
  }
}
