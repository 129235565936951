import { render, staticRenderFns } from "./gateDist.vue?vue&type=template&id=5d977c61&scoped=true"
import script from "./gateDist.vue?vue&type=script&lang=ts"
export * from "./gateDist.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d977c61",
  null
  
)

export default component.exports