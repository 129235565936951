/**
 * @name: 仓储管理-档口配货记录接口文件
 * @author:
 * @date: 2023-08-04 17:46
 * @description： 仓储管理-档口配货记录接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {IDistributeRecord} from "@/apis/storage/gateDist/types";

/**
 * 分页查询
 * @returns
 */
export const getDistributeRecordApi = (param:IDistributeRecord) => get("/admin/distribute/getDistributeRecord",param)

/**
 * 导出配货记录
 * @param params 查询参数
 * @returns
 */
export const exportDistributeRecordApi = (params: IDistributeRecord) => postJ("/admin/distribute/exportDistributeRecord", params,"blob")
